import React from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';

export const YM_TRACKING_ID = Number(process.env.NEXT_PUBLIC_YANDEX_METRICS_ID);

export const pageviewYM = (url: string) => {
  window.ym(YM_TRACKING_ID, 'hit', url);
};

export const eventYM = (target: string, params?: ym.VisitParameters) => {
  window.ym(YM_TRACKING_ID, 'reachGoal', target, params);
};

export const YMAnalyticsScript = () => {
  const router = useRouter();

  React.useEffect(() => {
    pageviewYM(window.location.pathname);
  }, []);

  React.useEffect(() => {
    router.events.on('routeChangeComplete', pageviewYM);
    router.events.on('hashChangeComplete', pageviewYM);
    return () => {
      router.events.off('routeChangeComplete', pageviewYM);
      router.events.off('hashChangeComplete', pageviewYM);
    };
  }, [router.events]);

  return (
    <>
      <Script
        defer
        id="ym-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
            m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
            (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

            ym(${YM_TRACKING_ID}, "init", {
                defer: true,
                clickmap:true,
                trackLinks:true,
                accurateTrackBounce:true,
                webvisor:true,
                trackHash:true
            });
          `,
        }}
      />

      {/* eslint-disable-next-line @next/next/no-img-element */}
      <noscript>
        <div>
          <img
            src={`https://mc.yandex.ru/watch/${YM_TRACKING_ID}`}
            style={{ position: 'absolute', left: -9999 }}
            alt=""
          />
        </div>
      </noscript>
    </>
  );
};
