import { useRouter } from 'next/router';
import { NextSeo, NextSeoProps } from 'next-seo';

import { env } from '@/shared/config/env.mjs';

import { APP_TITLE } from './meta';

interface PageSeoProps extends NextSeoProps {
  image?: string;
}

export const PageSeo = (props: PageSeoProps) => {
  const { title = APP_TITLE, description, image, ...other } = props;

  const { asPath } = useRouter();

  const canonicalLink = `${env.NEXT_PUBLIC_APP_URL}${asPath}`;

  let imageURL = `${env.NEXT_PUBLIC_APP_URL}/og-image.png`;

  if (image) {
    if (image?.startsWith('https://')) {
      imageURL = image;
    } else {
      imageURL = `${env.NEXT_PUBLIC_APP_URL}/${image}`;
    }
  }

  return (
    <NextSeo
      robotsProps={{
        maxSnippet: -1,
        maxVideoPreview: -1,
        maxImagePreview: 'large',
      }}
      canonical={canonicalLink}
      title={title}
      defaultTitle={APP_TITLE}
      description={description}
      titleTemplate={`${APP_TITLE} - %s`}
      twitter={{
        site: APP_TITLE,
        cardType: imageURL ? 'summary_large_image' : 'summary',
      }}
      openGraph={{
        siteName: APP_TITLE,
        locale: 'ru_RU',
        type: 'website',
        title: `${APP_TITLE} – ${title}`,
        description,
        url: canonicalLink,
        images: imageURL
          ? [
              {
                url: imageURL,
                width: 800,
                height: 600,
                alt: 'Preview image',
              },
            ]
          : undefined,
      }}
      {...other}
    />
  );
};
